<template>
  <v-card 
    width="100%"
    flat
  >
    <v-row
      no-gutters 
    >
      <v-card-title
        :style="{borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.3vh'}"
      >
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{height:'88%'}"
      class="pt-2"
    >
      <v-col>       
        <v-row
          no-gutters
        >
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <v-card-title                  
                class="pt-0"
              >
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Procurar na tabela"
                  single-line
                  hide-details
                />
              </v-card-title>
              <v-data-table
                dense
                :headers="headers"
                :items="items"
                :search="search"
              />
            </v-col>
          </v-row>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props:{
    title:{
      type: String,
      required: true,
      default: '',
    },
  },
  data(){
    return {
      search: '',
      headers: [
        {text:'Transação', value:'CE241_CD_TRANS'},
        {text:'Tp. pagamento', value:'CE241_TP_PGTO'},        
      ],
      items: [
        {CE241_CD_TRANS: 'APLICATIVOS DE TRANSPORTE', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'DESPESAS DIVERSAS', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'IMPOSTOS', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'FRETE/MOTOBOY', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'SUPERMERCADO', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'DEPÓSITO/SANGRIA', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'VALE TRANSPORTE', CE241_TP_PGTO: 'PAGAMENTO'},
        {CE241_CD_TRANS: 'MARKETING', CE241_TP_PGTO: 'PAGAMENTO'},
      ],
    }
  },
  watch:{
    
  },
  mounted(){

  },
  methods: {
    
  },
}
</script>